/* eslint-disable camelcase */
import enc from 'crypto-js';
import sha256 from 'crypto-js/sha256';

import { validatedPrice } from '../utils/utils';

const loginIdentify = (email) => {
  const userId = sha256(email).toString(enc.Hex);
  if (process.env.NEXT_PUBLIC_SEGMENT_ID) {
    window.analytics?.identify(userId, {
      email
    });
    window.analytics.track('Login', {
      userId, // event property
      email
    });
  }
};

export const loginFailed = (email) => {
  const userId = sha256(email).toString(enc.Hex);
  if (process.env.NEXT_PUBLIC_SEGMENT_ID) {
    window.analytics.track('Login Failed', {
      userId, // event property
      email
    });
  }
};

export const pageviewEvent = (page, country) => {
  if (process.env.NEXT_PUBLIC_SEGMENT_ID) {
    window.analytics?.page(page, {
      context: {
        location: {
          country
        }
      }
    });
  }
};

export const signUpIdentify = (signUpData, country) => {
  const userId = sha256(signUpData.email).toString(enc.Hex);
  if (process.env.NEXT_PUBLIC_SEGMENT_ID) {
    window.analytics?.identify(userId, {
      name: `${signUpData.firstName} ${signUpData.lastName}`,
      first_name: signUpData.firstName,
      last_name: signUpData.lastName,
      email: signUpData.email,
      country,
      phone_number: `${signUpData.isdCode}${signUpData.phoneNumber}`,
      phone: `${signUpData.isdCode}${signUpData.phoneNumber}`,
      date_of_birth: signUpData.dob,
      gender: signUpData.gender,
      marketing_opt_in: signUpData.receiveUpdates
    });
    window.analytics.track('Signed Up', {
      userId, // event property
      name: `${signUpData.firstName} ${signUpData.lastName}`,
      email: signUpData.email,
      country,
      phone_number: signUpData.phoneNumber,
      date_of_birth: signUpData.dob,
      gender: signUpData.gender
    });
  }
};

export const checkoutIdentify = (checkoutData, country) => {
  const userId = sha256(checkoutData.email).toString(enc.Hex);
  if (process.env.NEXT_PUBLIC_SEGMENT_ID) {
    window.analytics?.identify(userId, {
      name: `${checkoutData.firstName} ${checkoutData.lastName}`,
      first_name: checkoutData.firstName,
      last_name: checkoutData.lastName,
      email: checkoutData.email,
      country,
      phone_number: checkoutData.phoneNumber
    });
  }
};

export const updateIdentify = (userData, country) => {
  const userId = sha256(userData.email).toString(enc.Hex);
  if (process.env.NEXT_PUBLIC_SEGMENT_ID) {
    window.analytics?.identify(userId, {
      name: `${userData.firstName} ${userData.lastName}`,
      first_name: userData.firstName,
      last_name: userData.lastName,
      email: userData.email,
      country,
      phone_number: userData.phoneNumber,
      date_of_birth: userData.dob,
      gender: userData.gender
    });
  }
};

export const addToCartEvent = (cartId, cartData, type = 'add_to_cart_product', country) => {
  const productData = [];
  Object.values(cartData).forEach((item) => {
    productData.push({
      product_id: item.sku,
      sku: item.sku,
      tags: item.itemData.colorValues[item.color].tags,
      name: item.itemData.name,
      category: item.itemData.category,
      colour: item.color === 'DEFAULT' ? '' : item.color,
      price: item.amount,
      quantity: item.quantity,
      coupon: ''
    });
  });
  const product = productData[0];
  if (process.env.NEXT_PUBLIC_SEGMENT_ID) {
    window.analytics.track('Product Added', {
      cart_id: cartId,
      btn_location: type,
      cp_stage: 'Add to Cart',
      url_country: country === 'Việt Nam' ? 'Vietnam' : country,
      ...product
    },
    {
      context: {
        location: {
          country
        }
      }
    });
  }
};

export const cartViewEvent = (cartId, cartData, country) => {
  const product_id = []; const sku = []; const name = []; const category = [];
  const colour = []; const price = []; const quantity = []; const tags = [];
  const coupon = [];

  Object.values(cartData).forEach((item) => {
    product_id.push(item.sku);
    sku.push(item.sku);
    name.push(item.itemData?.name);
    category.push(item.itemData?.category);
    tags.push(item.itemData?.colorValues[item.color]?.tags);
    colour.push(item.color === 'DEFAULT' ? '' : item.color);
    price.push(item.amount);
    quantity.push(item.quantity);
    coupon.push('');
  });
  if (process.env.NEXT_PUBLIC_SEGMENT_ID) {
    window.analytics.track('Cart Viewed', {
      cart_id: cartId,
      product_id,
      sku,
      name,
      tags,
      category,
      colour,
      price,
      quantity,
      coupon,
      cp_stage: 'View Cart'
    },
    {
      context: {
        location: {
          country
        }
      }
    });
  }
};

export const couponApplyEvent = (cartId, coupon, country) => {
  if (process.env.NEXT_PUBLIC_SEGMENT_ID) {
    window.analytics.track('Coupon Code Applied', {
      cart_id: cartId,
      coupon: coupon.couponCode,
      coupon_name: coupon.couponCode,
      discount: coupon.couponDiscount,
      cp_stage: 'Promo Code Applied',
      error_message: 'pass'
    },
    {
      context: {
        location: {
          country
        }
      }
    });
  }
};

export const couponFailEvent = (cartId, error_message, country) => {
  if (process.env.NEXT_PUBLIC_SEGMENT_ID) {
    window.analytics.track('Coupon Code Applied', {
      cart_id: cartId,
      cp_stage: 'Promo Code Applied',
      error_message
    },
    {
      context: {
        location: {
          country
        }
      }
    });
  }
};

export const shopAllPageViewed = (country) => {
  if (process.env.NEXT_PUBLIC_SEGMENT_ID) {
    window.analytics.track('Shop All Page', {
    },
    {
      context: {
        location: {
          country
        }
      }
    });
  }
};

export const checkoutStartedEvent = (cartId, cartData, cartPriceData, shipping, appliedCoupons, country) => {
  const product_id = []; const sku = []; const name = []; const category = [];
  const colour = []; const price = []; const quantity = []; const tags = [];
  const coupon = appliedCoupons.join();
  Object.values(cartData).forEach((item) => {
    product_id.push(item.sku);
    sku.push(item.sku);
    name.push(item.itemData.name);
    category.push(item.itemData.category);
    tags.push(item.itemData?.colorValues[item.color]?.tags);
    colour.push(item.color === 'DEFAULT' ? '' : item.color);
    price.push(item.amount);
    quantity.push(item.quantity);
  });
  if (process.env.NEXT_PUBLIC_SEGMENT_ID) {
    window.analytics.track('Checkout Started', {
      cart_id: cartId,
      product_id,
      sku,
      name,
      category,
      colour,
      tags,
      price,
      quantity,
      cp_stage: 'Go to Checkout',
      subtotal: cartPriceData.total,
      discount: cartPriceData.discount,
      total: cartPriceData.cartTotal,
      shipping: shipping.amount,
      coupon,
      currency: cartPriceData.currency_code
    },
    {
      context: {
        location: {
          country
        }
      }
    });
  }
};

export const shippingViewEvent = (cartId, country) => {
  if (process.env.NEXT_PUBLIC_SEGMENT_ID) {
    window.analytics.track('Shipping Info Page Viewed', {
      cart_id: cartId,
      cp_stage: 'Shipping Info'
    },
    {
      context: {
        location: {
          country
        }
      }
    });
  }
};

export const paymentViewEvent = (cartId, total, country) => {
  if (process.env.NEXT_PUBLIC_SEGMENT_ID) {
    window.analytics.track('Payment Info Page Viewed', {
      cart_id: cartId,
      cp_stage: 'Payment Info',
      total
    },
    {
      context: {
        location: {
          country
        }
      }
    });
  }
};

export const placeOrderClickEvent = (cartId, order, checkWhatsapp, receiveUpdates, country, payment_method) => {
  if (process.env.NEXT_PUBLIC_SEGMENT_ID) {
    const userId = sha256(order.email).toString(enc.Hex);
    window.analytics.track('Place Order Clicked', {
      cart_id: cartId,
      order_id: order.id,
      cp_stage: 'Go to Payment',
      name: `${order.firstName}${order.lastName}`,
      email: order.email,
      subtotal: validatedPrice(order.amount),
      discount: order.discount,
      receive_updates: receiveUpdates,
      whatsapp_contact: checkWhatsapp,
      total: validatedPrice(order.totalAmount),
      shipping: order.shippingAmount,
      currency: order.currency,
      payment_method
    },
    {
      context: {
        location: {
          country
        }
      }
    });
    window.analytics?.identify(userId, {
      receive_updates: receiveUpdates,
      whatsapp_contact: checkWhatsapp
    });
  }
};

export const editCartClickEvent = (cartId, url, country) => {
  if (process.env.NEXT_PUBLIC_SEGMENT_ID) {
    window.analytics.track('Edit Cart Clicked', {
      cart_id: cartId,
      current_page_url: url
    },
    {
      context: {
        location: {
          country
        }
      }
    });
  }
};

export const removeCartEvent = (cartId, cartData, country) => {
  const productData = [];
  Object.values(cartData).forEach((item) => {
    productData.push({
      product_id: item.sku,
      sku: item.sku,
      name: item.itemData.name,
      category: item.itemData.category,
      tags: item.itemData.colorValues[item.color].tags,
      colour: item.color === 'DEFAULT' ? '' : item.color,
      price: item.amount,
      quantity: item.quantity,
      coupon: ''
    });
  });
  const product = productData[0];
  if (process.env.NEXT_PUBLIC_SEGMENT_ID) {
    window.analytics.track('Product Removed', {
      cart_id: cartId,
      cp_stage: 'Removed from Cart',
      ...product
    },
    {
      context: {
        location: {
          country
        }
      }
    });
  }
};

export const orderCompleteEvent = (order, appliedCoupons, orderedProducts, country) => {
  const coupon = appliedCoupons.join();
  const productData = [];
  orderedProducts.forEach((item) => {
    productData.push({
      product_id: item.sku,
      sku: item.sku,
      name: item.name,
      tags: item.tags,
      cart_id: order.cartId,
      category: item.category,
      colour: item.variant,
      price: item.price,
      quantity: item.quantity,
      coupon: ''
    });
  });
  if (process.env.NEXT_PUBLIC_SEGMENT_ID) {
    window.analytics.track('Order Completed', {
      cart_id: order.cartId,
      order_id: order.id,
      cp_stage: 'Order confirmation',
      name: `${order.firstName}${order.lastName}`,
      email: order.email,
      subtotal: validatedPrice(order.amount),
      discount: order.discount,
      products: productData,
      coupon_id: coupon,
      channel: 'website',
      total: validatedPrice(order.totalAmount),
      shipping: order.shippingAmount,
      currency: order.currency
    },
    {
      context: {
        location: {
          country
        }
      }
    });
  }
};

export const buttonClickEvent = (btnId, btnName, btnLocation, currentPage, topic, country) => {
  if (process.env.NEXT_PUBLIC_SEGMENT_ID) {
    window.analytics.track('Button Clicked', {
      btn_id: btnId,
      btn_name: btnName,
      btn_location: btnLocation,
      current_page: currentPage,
      topic
    },
    {
      context: {
        location: {
          country
        }
      }
    });
  }
};

export const faqExpandedEvent = (btnName, topic, country) => {
  if (process.env.NEXT_PUBLIC_SEGMENT_ID) {
    window.analytics.track('FAQ Question Expanded', {
      btn_name: btnName,
      faq_topic: topic
    },
    {
      context: {
        location: {
          country
        }
      }
    });
  }
};

export const storyPageScrolledEvent = (currentPage, section, country) => {
  if (process.env.NEXT_PUBLIC_SEGMENT_ID) {
    window.analytics.track('Product Story Page Scrolled', {
      current_page: currentPage,
      scroll_section: section
    },
    {
      context: {
        location: {
          country
        }
      }
    });
  }
};

export const homePageScrolledEvent = (currentPage, section, country) => {
  if (process.env.NEXT_PUBLIC_SEGMENT_ID) {
    window.analytics.track('Home Page Scrolled', {
      current_page: currentPage,
      scroll_section: section
    },
    {
      context: {
        location: {
          country
        }
      }
    });
  }
};

export const videoPlayedEvent = (currentPage, country) => {
  if (process.env.NEXT_PUBLIC_SEGMENT_ID) {
    window.analytics.track('Video Played', {
      current_page: currentPage
    },
    {
      context: {
        location: {
          country
        }
      }
    });
  }
};

export const infoOpenedEvent = (topic, context, country) => {
  if (process.env.NEXT_PUBLIC_SEGMENT_ID) {
    window.analytics.track('Product Page Information Section Expanded', {
      product_category: context,
      info_topic: topic
    },
    {
      context: {
        location: {
          country
        }
      }
    });
  }
};

export const reviewSectionExpanded = (current_page, product_category, country) => {
  if (process.env.NEXT_PUBLIC_SEGMENT_ID) {
    window.analytics.track('Review Section Expanded', {
      product_category,
      current_page
    },
    {
      context: {
        location: {
          country
        }
      }
    });
  }
};

export const ratingClickedEvent = (current_page, product_category, country) => {
  if (process.env.NEXT_PUBLIC_SEGMENT_ID) {
    window.analytics.track('Rating Clicked', {
      product_category,
      current_page
    },
    {
      context: {
        location: {
          country
        }
      }
    });
  }
};

export const guestCheckoutClicked = (country) => {
  if (process.env.NEXT_PUBLIC_SEGMENT_ID) {
    window.analytics.track('Guest Selected', {
    },
    {
      context: {
        location: {
          country
        }
      }
    });
  }
};

export const paymentUnsuccessfulEvent = (country, error) => {
  if (process.env.NEXT_PUBLIC_SEGMENT_ID) {
    window.analytics.track('Payment Unsuccessful', {
      payment_fail_reason: error
    },
    {
      context: {
        location: {
          country
        }
      }
    });
  }
};

export const productColorPicked = (country, colour, product) => {
  if (process.env.NEXT_PUBLIC_SEGMENT_ID) {
    window.analytics.track('Colour Picked', {
      product,
      colour
    },
    {
      context: {
        location: {
          country
        }
      }
    });
  }
};

export const productQuantityAdded = (country, product) => {
  if (process.env.NEXT_PUBLIC_SEGMENT_ID) {
    window.analytics.track('Add Quantity', {
      product
    },
    {
      context: {
        location: {
          country
        }
      }
    });
  }
};

export const productQuantitySubtracted = (country, product) => {
  if (process.env.NEXT_PUBLIC_SEGMENT_ID) {
    window.analytics.track('Subtract Quantity', {
      product
    },
    {
      context: {
        location: {
          country
        }
      }
    });
  }
};

export const saveWithBundlesClicked = (country, product_page) => {
  if (process.env.NEXT_PUBLIC_SEGMENT_ID) {
    window.analytics.track('Save with Bundles Clicked', {
      product_page
    },
    {
      context: {
        location: {
          country
        }
      }
    });
  }
};

export const categoryProductCardClicked = (country, product_category, product) => {
  if (process.env.NEXT_PUBLIC_SEGMENT_ID) {
    window.analytics.track('Category Product Card Clicked', {
      product_category,
      product
    },
    {
      context: {
        location: {
          country
        }
      }
    });
  }
};

export const categoryDropdownClicked = (country, product_category, product) => {
  if (process.env.NEXT_PUBLIC_SEGMENT_ID) {
    window.analytics.track('Category Dropdown Clicked', {
      product_category,
      product
    },
    {
      context: {
        location: {
          country
        }
      }
    });
  }
};

export const headerCategoryClicked = (country, product_category) => {
  if (process.env.NEXT_PUBLIC_SEGMENT_ID) {
    window.analytics.track('Header Category Clicked', {
      product_category
    },
    {
      context: {
        location: {
          country
        }
      }
    });
  }
};

export const shopAllClicked = (country, btn_location) => {
  if (process.env.NEXT_PUBLIC_SEGMENT_ID) {
    window.analytics.track('Shop All Clicked', {
      btn_location
    },
    {
      context: {
        location: {
          country
        }
      }
    });
  }
};

export const shopFavouritesClicked = (country, product_category, product) => {
  if (process.env.NEXT_PUBLIC_SEGMENT_ID) {
    window.analytics.track('Shop Favourites Clicked', {
      product_category,
      product
    },
    {
      context: {
        location: {
          country
        }
      }
    });
  }
};

export const headerCategoryDropdownClicked = (country, product_category, product) => {
  if (process.env.NEXT_PUBLIC_SEGMENT_ID) {
    window.analytics.track('Header Category Dropdown Clicked ', {
      product_category,
      product
    },
    {
      context: {
        location: {
          country
        }
      }
    });
  }
};

export const countryLanguageClicked = (country) => {
  if (process.env.NEXT_PUBLIC_SEGMENT_ID) {
    window.analytics.track('Change Country/Language Clicked', {
    },
    {
      context: {
        location: {
          country
        }
      }
    });
  }
};

export const countryLanguageChanged = (country) => {
  if (process.env.NEXT_PUBLIC_SEGMENT_ID) {
    window.analytics.track('Country/Language Changed', {
      country
    },
    {
      context: {
        location: {
          country
        }
      }
    });
  }
};

export const guideCardClicked = (country, card_selected) => {
  if (process.env.NEXT_PUBLIC_SEGMENT_ID) {
    window.analytics.track('Guide Card Clicked', {
      card_selected
    },
    {
      context: {
        location: {
          country
        }
      }
    });
  }
};

export const promoBannerDiscoverClicked = (country, product_category, product) => {
  if (process.env.NEXT_PUBLIC_SEGMENT_ID) {
    window.analytics.track('Promo Banner Discover Clicked', {
      product_category,
      product
    },
    {
      context: {
        location: {
          country
        }
      }
    });
  }
};

export const promoBannerClicked = (country, product_category) => {
  if (process.env.NEXT_PUBLIC_SEGMENT_ID) {
    window.analytics.track('Promo Banner Clicked', {
      product_category
    },
    {
      context: {
        location: {
          country
        }
      }
    });
  }
};

export const productCardClicked = (country, product_category, product) => {
  if (process.env.NEXT_PUBLIC_SEGMENT_ID) {
    window.analytics.track('Product Card CTA Clicked', {
      product_category,
      product
    },
    {
      context: {
        location: {
          country
        }
      }
    });
  }
};

export const productCardDiscoverClicked = (country, product_category, product) => {
  if (process.env.NEXT_PUBLIC_SEGMENT_ID) {
    window.analytics.track('Product Card Discover Clicked', {
      product_category,
      product
    },
    {
      context: {
        location: {
          country
        }
      }
    });
  }
};

export const categoryProductListClicked = (country, product_category, product) => {
  if (process.env.NEXT_PUBLIC_SEGMENT_ID) {
    window.analytics.track('Category Product List Clicked ', {
      product_category,
      product
    },
    {
      context: {
        location: {
          country
        }
      }
    });
  }
};
export const headerCategoryDropdownDiscoverClicked = (country, product_category) => {
  if (process.env.NEXT_PUBLIC_SEGMENT_ID) {
    window.analytics.track('Header Category Dropdown Discover Clicked', {
      product_category
    },
    {
      context: {
        location: {
          country
        }
      }
    });
  }
};
export const hamburgerMenuClicked = (country) => {
  if (process.env.NEXT_PUBLIC_SEGMENT_ID) {
    window.analytics.track('Hamburger Menu Clicked', {
    },
    {
      context: {
        location: {
          country
        }
      }
    });
  }
};
export const profileClicked = (country) => {
  if (process.env.NEXT_PUBLIC_SEGMENT_ID) {
    window.analytics.track('Profile Clicked', {
    },
    {
      context: {
        location: {
          country
        }
      }
    });
  }
};

export const profileMenuClicked = (country, menu_selected) => {
  if (process.env.NEXT_PUBLIC_SEGMENT_ID) {
    window.analytics.track('Profile Menu Clicked ', {
      menu_selected
    },
    {
      context: {
        location: {
          country
        }
      }
    });
  }
};

export const howItWorksClicked = (country, btn_location) => {
  if (process.env.NEXT_PUBLIC_SEGMENT_ID) {
    window.analytics.track('How It Works Clicked', {
      btn_location
    },
    {
      context: {
        location: {
          country
        }
      }
    });
  }
};

export const pricingClicked = (country, btn_location) => {
  if (process.env.NEXT_PUBLIC_SEGMENT_ID) {
    window.analytics.track('Pricing Clicked', {
      btn_location
    },
    {
      context: {
        location: {
          country
        }
      }
    });
  }
};

export const freeAssessmentClicked = (country, btn_location) => {
  if (process.env.NEXT_PUBLIC_SEGMENT_ID) {
    window.analytics.track('Free Assessment Clicked', {
      btn_location
    },
    {
      context: {
        location: {
          country
        }
      }
    });
  }
};

export const seeHowItWorksClicked = (country, btn_location) => {
  if (process.env.NEXT_PUBLIC_SEGMENT_ID) {
    window.analytics.track('See How It Works Clicked', {
      btn_location
    },
    {
      context: {
        location: {
          country
        }
      }
    });
  }
};

export const freeSmileAssessmentClicked = (country, btn_location) => {
  if (process.env.NEXT_PUBLIC_SEGMENT_ID) {
    window.analytics.track('Start my free smile assessment Clicked', {
      btn_location
    },
    {
      context: {
        location: {
          country
        }
      }
    });
  }
};

export const watchTheVideoClicked = (country, btn_location) => {
  if (process.env.NEXT_PUBLIC_SEGMENT_ID) {
    window.analytics.track('Watch The Video Clicked', {
      btn_location
    },
    {
      context: {
        location: {
          country
        }
      }
    });
  }
};

export const getFreeAssessmentClicked = (country, btn_location) => {
  if (process.env.NEXT_PUBLIC_SEGMENT_ID) {
    window.analytics.track('Get Free Assessment Clicked', {
      btn_location
    },
    {
      context: {
        location: {
          country
        }
      }
    });
  }
};

export const eligibilityClicked = (country, btn_location) => {
  if (process.env.NEXT_PUBLIC_SEGMENT_ID) {
    window.analytics.track('Eligibility Clicked', {
      btn_location
    },
    {
      context: {
        location: {
          country
        }
      }
    });
  }
};

export const learnAboutPricingClicked = (country, btn_location) => {
  if (process.env.NEXT_PUBLIC_SEGMENT_ID) {
    window.analytics.track('Learn About Pricing Clicked', {
      btn_location
    },
    {
      context: {
        location: {
          country
        }
      }
    });
  }
};

export const treatmentPlanClicked = (country, btn_location) => {
  if (process.env.NEXT_PUBLIC_SEGMENT_ID) {
    window.analytics.track('Treatment Plan Clicked', {
      btn_location
    },
    {
      context: {
        location: {
          country
        }
      }
    });
  }
};

export const startFreeAssessmentClicked = (country, btn_location) => {
  if (process.env.NEXT_PUBLIC_SEGMENT_ID) {
    window.analytics.track('Start Free Assessment Clicked', {
      btn_location
    },
    {
      context: {
        location: {
          country
        }
      }
    });
  }
};

export const alignerStoryPageExpanded = (country, location) => {
  if (process.env.NEXT_PUBLIC_SEGMENT_ID) {
    window.analytics.track('Aligner Story Page Zenyum Locations Expanded', {
      location
    },
    {
      context: {
        location: {
          country
        }
      }
    });
  }
};

export const alignerStoryPageFaqExpanded = (country, FAQ_number) => {
  if (process.env.NEXT_PUBLIC_SEGMENT_ID) {
    window.analytics.track('Aligner Story Page FAQ Expanded', {
      FAQ_number
    },
    {
      context: {
        location: {
          country
        }
      }
    });
  }
};

export const alignerFavouritesClicked = (country, product_category, product) => {
  if (process.env.NEXT_PUBLIC_SEGMENT_ID) {
    window.analytics.track('Aligner Favourites Clicked', {
      product_category,
      product
    },
    {
      context: {
        location: {
          country
        }
      }
    });
  }
};

export const exploreMoreClicked = (country, btn_location) => {
  if (process.env.NEXT_PUBLIC_SEGMENT_ID) {
    window.analytics.track('Explore More Clicked', {
      btn_location
    },
    {
      context: {
        location: {
          country
        }
      }
    });
  }
};

export const hiTrustPaymentStatus = (country, event, queryparams) => {
  if (process.env.NEXT_PUBLIC_SEGMENT_ID) {
    window.analytics.track(event, {
      queryparams
    },
    {
      context: {
        location: {
          country
        }
      }
    });
  }
};
export default loginIdentify;
